import React, { useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Sidenav from '../Components/Sidenav';
import { Container, Box } from '@mui/material';
import { MainPageStyles } from '../Styles/styles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  const [startAnimation, setStartAnimation] = useState(false);

  const accordionData = [
    {
      id: 'panel1',
      title: '1. Was ist Nova Meet und wie unterscheidet es sich von anderen Dating-Apps?',
      content: 'Nova Meet setzt auf eine einzigartige Herangehensweise im Online-Dating. Bei uns liegt der Fokus auf echter Kommunikation: Männer müssen immer den ersten Schritt machen und Nachrichten schreiben, während Frauen entscheiden können, ob sie auf eine Nachricht antworten oder ein "Like" senden möchten.Darüber hinaus bieten wir die Sektion „Nova Real Talk“ an, in der unsere Nutzer*innen über Themen wie Partnerschaft, Sexualität, Dating sowie spezifische Rubriken wie„ Mann im Fokus“ und „Frau im Fokus“ informiert werden.Zudem können Frauen die Kommunikationsqualität eines Mannes bewerten, und diese Durchschnittsbewertung wird auf dem Männerprofil angezeigt - so wird ehrliche und respektvolle Kommunikation gefördert.',
    },
    {
      id: 'panel2',
      title: '2. Wie funktioniert die Registrierung auf Nova Meet?',
      content: 'Die Registrierung bei Nova Meet ist einfach und flexibel. Du kannst dich über Google, Apple ID, Facebook oder deine Telefonnummer anmelden. Nachdem du dich angemeldet hast, wirst du gebeten, einige persönliche Daten anzugeben. Einige dieser Informationen sind Pflicht, um ein vollständiges Nutzerprofil zu erstellen, das für eine erfolgreiche Nutzung der Plattform entscheidend ist. Andere Daten, wie etwa Hobbys oder Interessen, sind optional, um dein Profil weiter zu personalisieren.',
    },
    {
      id: 'panel3',
      title: '3. Wie wird die Echtheit der Profile auf Nova Meet gewährleistet?',
      content: 'Um sicherzustellen, dass alle Profile echt sind, arbeiten wir mit dem renommierten Drittanbieter Daon zusammen. Über einen Live-Check können Nutzer*innen ihre Profilbilder verifizieren lassen. Dies stellt sicher, dass die auf dem Profil gezeigte Person tatsächlich diejenige ist, die die App nutzt, was für mehr Sicherheit und Vertrauen sorgt.',
    },
    {
      id: 'panel4',
      title: '4. Welche kostenpflichtigen Funktionen gibt es bei Nova Meet?',
      content: 'Unsere Philosophie ist es, Liebe so weit wie möglich kostenlos zu ermöglichen. Die meisten Funktionen unserer App sind daher kostenlos zugänglich. Es gibt nur eine Ausnahme: Männer können eine bestimmte Anzahl an Frauen innerhalb eines 12-Stunden-Fensters anschreiben. Wenn ein Mann mehr Nachrichten senden möchte, als dieses Kontingent zulässt, ist eine kleine Gebühr erforderlich. Wir glauben, dass dies eine faire Balance ist, um das Erlebnis für alle Nutzer*innen angenehm zu gestalten.',
    },
    {
      id: 'panel5',
      title: '5. Wie schützt Nova Meet meine Daten und Privatsphäre?',
      content: 'Wir legen großen Wert auf den Schutz deiner Daten und verwenden die höchsten Sicherheitsstandards. Deine Daten werden ausschließlich auf Servern von Amazon Web Services (AWS) in Europa gespeichert, die als eine der sichersten und zuverlässigsten Lösungen weltweit gelten. Zusätzlich nutzen wir SSL-Verschlüsselung, um sicherzustellen, dass alle Datenübertragungen zwischen deinem Gerät und unseren Servern vor unbefugtem Zugriff geschützt sind.Darüber hinaus entsprechen unsere Datenschutzrichtlinien vollständig den Bestimmungen der EU- Datenschutz-Grundverordnung (DSGVO). Das bedeutet, dass wir nur Daten erheben und speichern, die für den Betrieb der App notwendig sind, und dass deine Daten sicher und vertraulich behandelt werden. Weitere Details zum Umgang mit deinen Daten findest du in unseren Datenschutzbestimmungen.',
    },
    {
      id: 'panel6',
      title: '6. Welche Funktionen bietet Nova Meet für kostenlose Nutzer?',
      content:'Die meisten Funktionen auf Nova Meet sind komplett kostenlos nutzbar. Du kannst ein Profil erstellen, Nachrichten senden, empfangen und dich in unserer „Nova Real Talk“-Sektion über Themen wie Partnerschaft und Dating informieren. Auch die Bewertung von Kommunikationsstilen und die Anzeige von Bewertungen sind kostenlos. Unser Ziel ist es, die App so offen wie möglich zu gestalten, da wir glauben, dass Liebe kein Preisschild haben sollte.',
    },
    {
      id: 'panel7',
      title: '7. Kann ich Nova Meet auch nutzen, wenn ich keine Standortfreigabe erteile?',
      content:'Für eine vollständige Nutzung der App ist es erforderlich, zumindest den Ort anzugeben, an dem du wohnst oder suchst. Dies ermöglicht uns, dir potenzielle Partner*innen in deiner Nähe vorzuschlagen. Der genaue Standortzugriff (GPS) ist optional, aber zumindest die Eingabe deiner Stadt ist nötig, damit die App optimal funktioniert.',
    },
    {
      id: 'panel8',
      title: '8. Wie kann ich mein Profil auf Nova Meet löschen?',
      content:'Die Löschung deines Profils ist bei uns ganz einfach. In den Einstellungen der App findest du die Option, dein Konto dauerhaft zu löschen. Was mit deinen Daten nach der Löschung passiert, kannst du jederzeit in unseren Datenschutzbestimmungen nachlesen. Dort erklären wir genau, wie wir mit deinen Daten nach dem Löschen umgehen.',
    },
    {
      id: 'panel9',
      title: '9. Wie erhalte ich Hilfe, wenn ich Probleme oder Fragen zu Nova Meet habe?',
      content:'Wenn du Fragen hast oder Hilfe benötigst, kannst du uns jederzeit per E-Mail unter info@nova-dating.com erreichen. Unser Support-Team steht dir zur Verfügung und hilft dir gerne weiter.',
    },

  ];

  const [expanded, setExpanded] = useState(false); // State to track the active accordion

  const handleChange = (panel) => (event, isExpanded) => {
    // If the panel is expanded, close it; otherwise, open the clicked panel
    setExpanded(isExpanded ? panel : false);
  };

  return (

    <>
      <Box sx={MainPageStyles.mainDiv}>
        <Container maxWidth="lg">
          <Header startAnimation={startAnimation} setStartAnimation={setStartAnimation} />
          <Box sx={MainPageStyles.bodyDiv}>
            {!startAnimation ?
              <>
                {accordionData.map((accordion) => (
                  <Accordion key={accordion.id} expanded={expanded === accordion.id} // Set expanded based on state
                    onChange={handleChange(accordion.id)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${accordion.id}-content`}
                      id={`${accordion.id}-header`}
                    >
                      {accordion.title}
                    </AccordionSummary>
                    <AccordionDetails>
                      {accordion.content}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
              :
              <>
                <Sidenav />
              </>
            }
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  )
}

export default Faq
