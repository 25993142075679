import React from 'react';
import CommonRoutes from './commonRoutes';
import PrivateRoutes from './privateRoutes';

const Router = () => {
  return (
    <>
      <CommonRoutes />
      <PrivateRoutes />
    </>
  )
}

export default Router
