import Box from '@mui/material/Box';
import logo from '../Assests/Images/logo.svg';
import menu from '../Assests/Images/menu.svg';
import close from '../Assests/Images/close.svg';
import { motion } from "framer-motion";
import { HeaderStyles } from '../Styles/styles';

const Header = ({ startAnimation, setStartAnimation }) => {
    const handleClick = () => {
        setStartAnimation(!startAnimation);
    }
    return (
        <>
            <Box sx={HeaderStyles.mainDiv}>
                <img src={logo} alt="logo" width={150} height={'auto'} />
                <img src={startAnimation ? close : menu } alt="menu" width={50} height={'auto'} onClick={handleClick} />
            </Box>
            {startAnimation && (
                <>
                    <motion.div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            borderRadius: '0vw 100vw 100vw 0vw', // To create a circular shape
                            border: '20px solid #fff', // Ring thickness and color
                            backgroundColor: '#fff', // Make the background transparent to see the ring
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            x: 0,
                        }}
                        animate={{ x: '-100vw', opacity: 0 }} // Move left, fade out
                        transition={{ duration: 2, ease: 'easeInOut' }}
                    />
                </>
            )}

        </>
    )
}

export default Header
