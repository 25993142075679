import './App.css';
import React, { useState, useEffect } from 'react';
import Router from './Routes/router';
import cursor from './Assests/Images/cursor.svg';

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Update cursor position on mouse move
  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX+10, y: event.clientY+10 });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  return (
    <div className="custom-cursor">
      <Router />
      <div
        className="cursor-element"
        style={{ top: `${position.y}px`, left: `${position.x}px` }}
      >
        <img src={cursor} alt="menu" />
      </div>
    </div>
  );
}

export default App;
