import React from 'react'
import { Link } from '@mui/material';
import { MainPageStyles } from '../Styles/styles';

const Sidenav = () => {
    return (
        <>
            <Link index sx={MainPageStyles.text2}>Home</Link>
            <Link href="/faq" sx={MainPageStyles.text2}>FAQ's</Link>
            <Link href="/imprint" sx={MainPageStyles.text2}>Impressum </Link>
        </>
    )
}

export default Sidenav
