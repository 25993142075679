import React from 'react';
import { FooterStyles } from '../Styles/styles';
import { Box, Container, Link } from '@mui/material';
import CookiePolicy from '../Assests/Pdf/CookiePolicy.pdf';
import TermsOfUse from '../Assests/Pdf/TermsofUse.pdf';
import PrivacyPolicy from '../Assests/Pdf/PrivacyPolicy.pdf';
const Footer = () => {
  return (
    <>
      <Box sx={FooterStyles.mainDiv}>
        <Container maxWidth="md" sx={FooterStyles.container}>
          <Link href={PrivacyPolicy} sx={FooterStyles.anchorText}>Privacy Policy</Link>
          <Link href={CookiePolicy} sx={FooterStyles.anchorText}>Cookie Policy</Link>
          <Link href={TermsOfUse} sx={FooterStyles.anchorText}>Terms of Use</Link>
        </Container>
      </Box>

    </>
  )
}

export default Footer
