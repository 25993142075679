
import { createTheme } from '@mui/material/styles';  
import { ThemeColors } from './globalStyles';
import { text } from 'framer-motion/client';

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });


export const HeaderStyles = {
    mainDiv:{
        color: ThemeColors.textPrimary,
        height: '10vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
    },

    headerLogo:{
        fontSize: '2.5rem',
        cursor: 'pointer',
        color: '#fff',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: 'bold',
    }
}

export const MainPageStyles = {
    mainDiv:{
        color: ThemeColors.textPrimary,
        backgroundImage: 'linear-gradient(180deg, #BDC1FE, #8A91FF)',
        height: '100vh',
        overflow: 'auto',
    },

    bodyDiv:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        minHeight: 'calc(80vh - 2.2rem)',
        borderTop:'1px solid white',
        overflow: 'auto',
    },

    text:{
        fontSize: '4rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '1000',
        textAlign: 'center',
        textStroke: '1px black',
        WebkitTextStroke: '1px #00000080',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5rem',
        },
    },

    text2:{
        fontSize: '3.5rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '900',
        textAlign: 'center',
        textStroke: '1px white',
        marginTop: '1rem',
        color: '#fff',
        cursor: 'pointer',
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
    },

    text3:{
        fontSize: '1rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '200',
        textAlign: 'center',
        textStroke: '1px white',
        WebkitTextStroke: '1px white',
        color: '#fff',
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },

    text4:{
        fontSize: '2rem',
        color: '#fff',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '1000',
        textAlign: 'center',
        textStroke: '1px black',
        WebkitTextStroke: '1px #00000080',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5rem',
        },
    },
    miniheading:{
        fontSize: '1.5rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '800',
        textStroke: '1px black',
        color: '#fff',
        textAlign: 'left',
        WebkitTextStroke: '1px #00000080',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5rem',
        },
    },

    Question:{
        fontSize: '1.2rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '500',
        textAlign: 'left',
        textStroke: '1px white',
        WebkitTextStroke: '1px white',
        color: '#fff',
        textDecoration: 'none',
        
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },

    answer:{
        fontSize: '1rem',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: '200',
        textAlign: 'center',
        textStroke: '1px white',
        WebkitTextStroke: '1px white',
        color: '#fff',
        textAlign: 'left', px:2,
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    }
}

export const FooterStyles = {
    mainDiv:{
        color: ThemeColors.textPrimary,
        height: '10vh',
        borderTop: '1px solid #fff',
    },

    anchorText:{
        color: '#fff',
        cursor: 'pointer',
        fontFamily: ThemeColors.fontFamily,
        fontWeight: 'bold',
        textDecoration: 'none',
        cursor: 'none',
        "&:hover":{
            color: '#4521A0',
            textDecoration: 'underline',
        }
    },

    container:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
    }
}
