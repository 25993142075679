import React, { useState } from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Sidenav from '../Components/Sidenav';
import { Container, Box, Typography } from '@mui/material';
import { MainPageStyles } from '../Styles/styles';

const CookiePolicy = () => {
    const [startAnimation, setStartAnimation] = useState(false);
    return (
        <Box sx={MainPageStyles.mainDiv}>
            <Container maxWidth="lg">
                <Header startAnimation={startAnimation} setStartAnimation={setStartAnimation} />
                <Box sx={MainPageStyles.bodyDiv}>
                    {!startAnimation ?
                        <Typography sx={MainPageStyles.text}>Cookie Policy</Typography>
                        :
                        <Sidenav />
                    }
                </Box>
            </Container>
            <Footer />
        </Box>
    )
}

export default CookiePolicy
