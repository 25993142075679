import React, { useState } from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Sidenav from '../Components/Sidenav';
import { Container, Box, Typography } from '@mui/material';
import { MainPageStyles } from '../Styles/styles';

const PrivacyPolicy = () => {
    const [startAnimation, setStartAnimation] = useState(false);

    const QuestionData = [
        {
            Question: "1. Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen",
            answer: "Welche Informationen erfassen wir bei der Nutzung unserer Services und wer ist für die Verarbeitung deiner Daten verantwortlich?"
        },
        {
            Question: "2. Welche Informationen werden bei der Nutzung unserer Services erhoben und wie nutzen wir diese Informationen?",
            answer: "Details darüber, welche personenbezogenen Daten wir erfassen und wie wir diese verwenden."
        },
        {
            Question: "3. Weitergabe von Daten an Dritte und deren Zweck",
            answer: "Wir verarbeiten personenbezogene Daten nur, wenn sie zur Vertragserbringung oder Vertragsabwicklung erforderlich sind."
        },
    ]

    const QuestionAns = ({ Question, answer }) => {
        return (
            <Box sx={{ mb:2 }}>
                <Typography sx={MainPageStyles.Question}>
                    {Question}
                </Typography>
                <Typography sx={MainPageStyles.answer}>
                    {answer}
                </Typography>
            </Box>)
    }
    return (
        <Box sx={MainPageStyles.mainDiv}>
            <Container maxWidth="lg">
                <Header startAnimation={startAnimation} setStartAnimation={setStartAnimation} />
                <Box sx={MainPageStyles.bodyDiv}>
                    {!startAnimation ?
                        <>
                            {/* Heading */}
                            <Typography sx={{ ...MainPageStyles.text4, textAlign: 'left', my: 1 }}>Vorwort: Deine Daten gehören dir</Typography>
                            <Typography sx={{ ...MainPageStyles.text3, textAlign: 'left' }}>
                                Wir legen großen Wert auf den Schutz deiner Daten. Deshalb speichern und verarbeiten wir
                                ausschließlich Daten, die für den Betrieb der App erforderlich sind oder uns als Entwickler dabei
                                helfen, die App kontinuierlich zu verbessern. Unser Geschäftsmodell basiert auf einem hohen
                                Datenschutzstandard. Wir schalten keine Werbung und geben deine Daten nicht an Dritte weiter.
                            </Typography>
                            <Typography sx={{ ...MainPageStyles.text3, textAlign: 'left' }}>
                                Unsere App ist so gestaltet, dass der Großteil der Funktionen kostenlos genutzt werden kann.
                                Zusätzlich bieten wir ein günstiges Abonnementmodell an, das es uns ermöglicht, die App nachhaltig
                                zu betreiben, ohne auf den Verkauf von Nutzerdaten angewiesen zu sein. Sollten sich unsere
                                Datenschutzpraktiken in Zukunft ändern, werden wir dich selbstverständlich darüber informieren.
                            </Typography>

                            <Typography sx={{ ...MainPageStyles.text4, textAlign: 'left', my: 1 }}>Inhaltsverzeichnis</Typography>
                            
                            {/* Questions  */}
                            {QuestionData.map(({ Question, answer }) => {
                                return (
                                <QuestionAns key={Question} Question={Question} answer={answer} />)
                            })}







                        </>
                        :
                        <Sidenav />
                    }
                </Box>
            </Container>
            <Footer />
        </Box>
    )
}

export default PrivacyPolicy
