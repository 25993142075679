import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home';
import Faq from '../Pages/Faq';
import Imprint from '../Pages/Imprint';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsOfUse from '../Pages/TermsOfUse';
import CookiePolicy from '../Pages/CookiePolicy';

const CommonRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </>
  )
}

export default CommonRoutes
