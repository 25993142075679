import React, { useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Sidenav from '../Components/Sidenav';
import { Container, Box, Paper, styled, Typography } from '@mui/material';
import { MainPageStyles } from '../Styles/styles';
import Grid from '@mui/material/Grid2';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  border: '1px solid #fff',
  borderRadius: '1rem',
}));

const Faq = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  return (
    <>

      <Box sx={MainPageStyles.mainDiv}>
        <Container maxWidth="lg">
          <Header startAnimation={startAnimation} setStartAnimation={setStartAnimation} />
          <Box sx={MainPageStyles.bodyDiv}>
            {!startAnimation ?
              <>
                <Typography sx={{...MainPageStyles.text2, pb:2}}>Impressum</Typography>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Item>
                      <Typography sx={MainPageStyles.text3}>Angaben gemäß § 5 TMG (Telemediengesetz):</Typography>
                      <Typography sx={MainPageStyles.text3}>Nova Holding UG (haftungsbeschränkt)</Typography>
                      <Typography sx={MainPageStyles.text3}>Geschäftsführer: Jonathan Köbelin</Typography>
                      <Typography sx={MainPageStyles.text3}>Merzhauserstr. 159f</Typography>
                      <Typography sx={MainPageStyles.text3}>Handelsregister: HRB 730577</Typography>
                    </Item>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Item>
                      <Typography sx={MainPageStyles.text3}>Registergericht: Amtsgericht Freiburg</Typography>
                      <Typography sx={MainPageStyles.text3}>Verantwortlich für den Inhalt gemäß § 55 Abs. 2 RStV:</Typography>
                      <Typography sx={MainPageStyles.text3}>Jonathan Köbelin</Typography>
                      <Typography sx={MainPageStyles.text3}>Merzhauserstr. 159f</Typography>
                      <Typography sx={MainPageStyles.text3}>79100 Freiburg, Deutschland</Typography>
                    </Item>
                  </Grid>
                  <Typography sx={MainPageStyles.text3}>Hinweis zur Online-Streitbeilegung (OS):Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                  https://ec.europa.eu/consumers/odr. Unsere E-Mail-Adresse finden Sie oben im Impressum.</Typography>
                </Grid>
              </>
              :
              <>
                <Sidenav />
              </>
            }
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  )
}

export default Faq
